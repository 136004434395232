<template>
    <div class="container">

        <h2>CSV</h2>

        <form class="form mb-3">
            <label for="csvData">CSV</label>
            <div><textarea id="csvData" v-model="filesCsvText" class="form-control" style="width: 100%; height: 100px;"></textarea></div>
            <div><button class="btn btn-outline-secondary" @click="loadFiles" type="button">Load</button></div>
        </form>

        <h2>Files Table</h2>

        <div class="my-2">
            {{loadedFiles.length}} files in table
            <button class="btn btn-outline-secondary mx-2" @click="loadAllUsages" type="button"
                    :disabled="loadedFiles.length === 0 || !!loadingUsagesForFileId">Load Usages</button>
            <button class="btn btn-outline-secondary mx-2" @click="removeAllUsages" type="button"
                    :disabled="loadedFiles.length === 0 || !!loadingUsagesForFileId || !loadingUsageDone || !!removingUsagesForFileId">Remove all usages</button>
        </div>

        <table class="table table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Usages</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="loadedFile in loadedFiles" :key="loadedFile.id">
                    <td>{{loadedFile.id}}</td>
                    <td>{{loadedFile.name}}</td>
                    <td>
                        <div v-if="loadedFile.loadedUsagesDone && loadedFile.name !== loadedFile.usageName" class="alert alert-danger">
                            Name {{loadedFile.name}} does not match {{ loadedFile.usageName }}
                        </div>

                        <div v-if="removingUsagesForFileId === loadedFile.id">Removing...</div>
                        <div v-else-if="loadingUsagesForFileId === loadedFile.id">Loading...</div>
                        <div v-else-if="loadedFile.loadedUsagesDone">
                            <div>{{loadedFile.loadedUsages.length}} usages</div>
                            <div v-for="(usage, idx) in loadedFile.loadedUsages" :key="'usage-' + loadedFile.id + '-' + idx">
                                {{usage.type}} {{usage.presentation_id}}
                            </div>
                        </div>
                        <div v-else>No loaded</div>
                    </td>
                </tr>
            </tbody>
        </table>

        <h2>Log</h2>

        <div>
            <div v-for="(line, idx) in removeUsagesLogArr" :key="'line' + idx">
                {{line}}
            </div>
        </div>


    </div>
</template>


<script>

import $ from "jquery";
import ScNotification from "../../shared/common/ScNotification.vue";
import Papa from 'papaparse';
import _ from "underscore"; // each
import ScConfirmModal2 from "../../shared/common/ScConfirmModal2.vue";
import MainAppMixin from "../../components/MainAppMixin";

export default {
    name: 'DebugFileUsages',
    mixins: [ MainAppMixin ],
    data: function() {
        return {
            filesCsvText: '10398,bk5000 Neuro pres test (1).pptx\n' +
                '10400,Slide 01.png',
            loadedFiles: [],
            loadingUsageDone: false,
            loadingUsagesForFileId: null,
            removingUsagesForFileId: null,
            removeUsagesLogArr: []
        };
    },

    mounted: function() {

    },

    methods: {
        loadFiles() {
            if (this.filesCsvText) {
                let csvData = this.filesCsvText.replace(/\r\n/g, "\n");
                csvData = csvData.replace(/\r/g, "\n");
                let csvObj = Papa.parse(csvData);
                this.loadedFiles.splice(0);
                _.each(csvObj.data, (lineArr) => {
                    if (lineArr.length === 0) return;
                    let fileObj = {id: null, name: null, loadedUsages: [], loadedUsagesDone: false, usageName: null};
                    fileObj.id = Number(lineArr[0]);
                    fileObj.name = lineArr[1];
                    this.loadedFiles.push(fileObj);
                });
            }
        },
        async loadAllUsages() {
            this.loadingUsageDone = false;
            for (const loadedFile of this.loadedFiles) {
                loadedFile.loadedUsagesDone = false;
            }
            for (const loadedFile of this.loadedFiles) {
                await this.loadUsageData(loadedFile);
            }
            this.loadingUsageDone = true;
        },
        removeAllUsages() {
            ScConfirmModal2.modal('Are you sure?').then(async () => {
                for (const loadedFile of this.loadedFiles) {
                    if (loadedFile.loadedUsages.length > 0) {
                        await this.replaceUsages(loadedFile);
                    }
                }
                ScNotification.growlSuccessMsg('All done.')
            });
        },
        loadUsageData(fileObj) {
            return new Promise((resolve, reject) => {
                this.loadingUsagesForFileId = fileObj.id;
                fileObj.loadedUsages.splice(0);
                $.ajax({
                    method: 'GET', url: '/main/library/ajax_find_resource_usage_details',
                    data: {resource_id: fileObj.id, workspace_id: this.mainMxCurrentWorkshopId}
                }).done((data) => {
                    fileObj.loadedUsages.push(...data.usages);
                    fileObj.usageName = data.resource.name;
                    fileObj.loadedUsagesDone = true;
                    resolve();
                }).always(() => {
                    this.loadingUsagesForFileId = null;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'loading usages');
                    reject();
                });
            });
        },
        replaceUsages (fileObj) {
            return new Promise((resolve, reject) => {
                this.removingUsagesForFileId = fileObj.id;
                $.ajax({
                    method: 'POST', url: '/main/library/ajax_replace_resource_usages',
                    data: {
                        src_resource_id: fileObj.id,
                        dest_resource_id: -1, workspace_id: this.mainMxCurrentWorkshopId
                    }
                }).done(async () => {
                    this.removeUsagesLogArr.push('Removed ' + fileObj.loadedUsages.length + ' usages of file ' + fileObj.id +
                        ' ' + fileObj.name);
                    await this.loadUsageData(fileObj);
                    resolve();
                }).always(() => {
                    this.removingUsagesForFileId = null;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'replacing');
                    reject();
                });
            });
        }
    }
};

</script>
