<template>
  <div class="my-4">
    <h2 id="unusedplids">Unused Pagelist Ids</h2>
    <div>
      <span v-for="(id, i) in unusedPageListIds" :key="'upl'+i">
        {{id}} <button type="button" class="btn" @click.prevent="removePageList(id)">&times;</button>
      </span>
    </div>
  </div>
</template>


<script>

import $ from 'jquery';
import ScNotification from '../../shared/common/ScNotification.vue';

export default {
  name: 'DebugUnusedPageLists',
  components: {},
  props: {
    workspaceId: {type: Number, default: null},
    presentationId: {type: Number, default: null},
    presData: {type: Object, default: null},
  },
  data: function() {
    return {
      deletedPageListIds: [],
    };
  },

  mounted: function() {

  },

  methods: {

    removePageList(pagelistId) {
      if (!confirm(`Delete pagelist ${pagelistId}, this is mostly UNTESTED, are you sure?`)) return;
      $.ajax({
        method: 'POST', url: '/main/editor/ajax_delete_pagelist_v2',
        data: { workspace_id: this.workspaceId, presentation_id: this.presentationId,
          pagelist_id: pagelistId }
      }).done(() => {
        this.deletedPageListIds.push(pagelistId);
        ScNotification.growlSuccessMsg('Done.  Reload to see updated pres.');
      }).fail((jqXhr) => {
        ScNotification.growlXhrError(jqXhr, 'deleting pagelist');
      });
    },

  },

  computed: {

    unusedPageListIds() {
      let scData = this.presData;
      if (!scData || !scData.presentationmetadata) return [];

      let walkTree = function (pagelistId) {
        if (walkedPagelistIds.indexOf(pagelistId) > -1) return;  // we already did this one
        walkedPagelistIds.push(pagelistId);
        let pages = Object.values(scData.page).filter(p => p.pagelist_id === pagelistId);
        pages.forEach((page) => {
          let hotspotsForPage = Object.values(scData.hotspot).filter(h => h.parent_page_id === page.id);
          hotspotsForPage.forEach((hotspot) => {
            if (hotspot.target_page_id) {
              let targetPage = Object.values(scData.page).find(tp => tp.id === hotspot.target_page_id);
              if (targetPage) walkTree(targetPage.pagelist_id);
            }
          });
        });
      };
      let walkedPagelistIds = [];
      walkTree(scData.presentationmetadata.root_pagelist_id);
      // now find what pagelists can be navigated to
      let unusedPagelistIds = [];
      Object.values(scData.pagelist).forEach((pl) => {
        let alreadyDeleted = this.deletedPageListIds.includes(pl.id);
        if (!walkedPagelistIds.includes(pl.id) && !alreadyDeleted) unusedPagelistIds.push(pl.id);
      });
      return unusedPagelistIds;
    }
  }
};




</script>