<template>
    <div class="container">
        <h2>Presentation {{presentationId}}
            <ScIcon v-if="presLoading" name="spinnerFW" class="text-muted"/>
        </h2>

        <div>
             <div class="form-check">
                 <input class="form-check-input" type="checkbox" name="draft" id="draft" v-model="draftPresentation">
                 <label class="form-check-label" for="draft">Use draft</label>
             </div>

            <a href="#" class="btn btn-outline-secondary" @click.prevent="reloadPresentation()">Reload</a>

            <a href="#" class="btn btn-outline-secondary" @click.prevent="compileDraftPres()">Compile Draft</a>

            <a href="#" class="btn btn-outline-secondary" @click.prevent="checkPresentation()">Check resources publishable, fix page renders</a>

            <a href="#" class="btn btn-outline-secondary" @click.prevent="removeRenderedPages()">Remove rendered pages</a>

            <button type="button" class="btn btn-outline-secondary" @click.prevent="allHostpostToPaddingSm()">All hotspots to padding small</button>

            <a href="#" class="btn btn-outline-secondary" @click.prevent="checkAllUrls()">Check all URLs</a>

            <router-link :to="{name: 'app-link-choose', params: { workshopId: workspaceId, showcaseId: presentationId}}" class="btn btn-link">App Link Chooser</router-link>

            <div class="float-end">
                <label><input type="file" class="form-control" @change="presFromFile($event)" accept=".json,.showcase"></label>
            </div>

        </div>

        <div v-if="presPublishable !== null">
            Publishable: {{ presPublishable }}

            Problems: <p :key="'pp' + idx" v-for="(prob, idx) in presProblems">
                {{ idx }}: {{ prob }}
            </p>
        </div>


        <ul class="nav nav-pills">
            <li class="nav-item"><a class="nav-link" href="#pagelists">Pagelists</a></li>
            <li class="nav-item"><a class="nav-link" href="#pages">Pages</a></li>
            <li class="nav-item"><a class="nav-link" href="#hotspots">Hotspots</a></li>
            <li class="nav-item"><a class="nav-link" href="#resources">Resources</a></li>
            <li class="nav-item"><a class="nav-link" href="#all-res-ids">All target resource IDs</a></li>
            <li class="nav-item"><a class="nav-link" href="#resourcemetadata">Resource Metadata</a></li>
            <li class="nav-item"><a class="nav-link" href="#unusedplids">Unused Pagelistids</a></li>
            <li class="nav-item"><a class="nav-link" href="#presstats">Presentation Stats</a></li>
            <li class="nav-item"><a class="nav-link" href="#resourcestats">Resource Stats</a></li>
            <li class="nav-item"><a class="nav-link" href="#searchindex">Search index</a></li>
        </ul>

        <div class="my-4">
            <h2>Presentation</h2>
            <div>{{ presData.presentation }}</div>
        </div>

        <div class="my-4">
            <h2>Presentation Metadata</h2>
            <div>{{ presData.presentationmetadata }}</div>
        </div>

        <div class="my-4">
        <h2 id="pagelists">Page Lists</h2>
        <div v-for="pagelist in presData.pagelist" :key="'pl'+pagelist.id">
            <strong>{{ pagelist.id }}</strong>
            {{ pagelist }}
        </div>
        </div>

        <div class="my-4">
        <h2 id="pages">Pages</h2>
        <div v-for="page in presData.page" :id="'page-' + page.id" :key="'pg'+page.id" class="my-3">

            <div>
                <img v-if="!isPageThumbGenerating(page.id) && page.resource_pagethumb_id && presData.resource[page.resource_pagethumb_id]"
                     :src="presData.resource[page.resource_pagethumb_id].thumb_url" :alt="page.resource_pagethumb_id"
                     style="max-width: 50px; max-height: 50px;" />&nbsp;

                <strong>{{ page.id }}</strong>&nbsp;

                background_resource_id:
                <a :href="'#resource-' + page.background_resource_id">{{ page.background_resource_id }}</a>&nbsp;
                <span v-if="page.background_resource_id !== page.background_src_resource_id">(rendered)</span>&nbsp;

                background_src_resource_id:
                <a :href="'#resource-' + page.background_src_resource_id">{{ page.background_src_resource_id }}</a>&nbsp;
                &nbsp;
                <button v-if="!isPageThumbGenerating(page.id)" class="btn btn-outline-secondary"
                        v-on:click.prevent="regeneratePageRender(page.id)">Start Render</button>&nbsp;
                <ScIcon v-if="isPageThumbGenerating(page.id)"
                        name="spinnerSmall" class="text-muted"/>
            </div>
            {{ page }}
        </div>
        </div>


        <div class="my-4">
        <h2 id="hotspots">Hotspots</h2>
        <div v-for="hotspot in presData.hotspot" :key="'hs'+hotspot.id">
            <strong>{{ hotspot.id }}</strong>
            {{ hotspot }}
        </div>
        </div>

        <div class="my-4">
        <h2 id="resources">Resources</h2>
        <div v-for="res in resourcesExcludingThumbs" :id="'resource-' + res.id" :key="'r'+res.id"
             class="border-top" style="margin-top: 15px;">

            <strong>{{ res.id }}</strong>
            <a v-if="res.content_type !== 'image'" :href="res.url">{{res.name}}</a>

            <div v-if="res.thumb_url" class="border">
                Thumb
                <span v-if="!isResourceThumbGenerating(res.id)">
                    (<a href="#" v-on:click.prevent="regenerateResourceThumb(res.id)">regenerate</a>)
                </span>
                <br>
                <ScIcon v-if="isResourceThumbGenerating(res.id)"
                        name="spinnerSmall" class="text-muted"/>
                <a v-if="!isResourceThumbGenerating(res.id)" :href="res.thumb_url">
                    <img :src="res.thumb_url" :alt="res.id" class="border"
                         style="max-width: 400px; max-height: 400px;" />
                </a>
            </div>

            <div v-if="res.url">
                <a :href="res.url">
                    Actual<br>
                    <img v-if="res.content_type === 'image'" :src="res.url" :alt="res.id" class="border"
                         style="max-width: 400px; max-height: 400px;" />
                </a>
            </div>

            {{ res }}
        </div>
        </div>

        <div class="my-4">
        <h2 id="all-res-ids">All target resource IDs</h2>
        <div>
            <span v-for="resId in targetResourceIds" :key="'rids'+resId">{{resId}}, </span>
        </div>
        </div>

        <div class="my-4">
        <h2 id="resourcemetadata">Resource Metadata</h2>
        <div v-for="rmd in presData.resourcemetadata" :key="'rmd'+rmd.id">
            <strong>{{ rmd.id }}</strong>
            {{ rmd }}
        </div>
        </div>

        <div class="my-4">
        <h2 id="presstats">Presentation Stats</h2>
            <div>numPagelists: {{ presStats.numPagelists }}</div>
            <div>numPages: {{ presStats.numPages }}</div>
            <div>numHotspots: {{ presStats.numHotspots }}</div>
            <div>numResources: {{ presStats.numResources }}</div>
            <div>numHotspotBranding: {{ presStats.numHotspotBranding }}</div>
            <div>numResourceMetadata: {{ presStats.numResourceMetadata }}</div>
            <div>numShareableResource: {{ presStats.numShareableResource }}</div>
            <div>numShareablePageResource: {{ presStats.numShareablePageResource }}</div>
        </div>

        <div class="my-4">
        <h2 id="resourcestats">Resource Stats</h2>
        <div v-for="smt in resourceStatsByContentType" :key="'smt'+smt.contentType">
            <strong>{{ smt.contentType }}</strong>
            <span class="ms-2">number: {{ smt.numResources }}</span>
            <span class="ms-2">size: {{ fmtByteSize(smt.totalBytes) }} ({{ smt.totalBytes }})</span>
            <span v-if="smt.totalBytes1024Video" class="ms-2">SD video size: {{ fmtByteSize(smt.totalBytes1024Video) }} ({{smt.totalBytes1024Video}})</span>
            <span v-if="smt.totalBytes2048Video" class="ms-2">HD video size: {{ fmtByteSize(smt.totalBytes2048Video) }} ({{smt.totalBytes2048Video}})</span>
        </div>
        </div>

        <DebugUnusedPageLists v-if="presData"
          :workspaceId="workspaceId" :presentationId="presentationId" :presData="presData"></DebugUnusedPageLists>

        <div class="my-4">
            <h2 id="searchindex">Search Index</h2>
            <DebugPresentationSearchIndex :workspaceId="workspaceId" :presentationId="presentationId"></DebugPresentationSearchIndex>
        </div>
    </div>


</template>


<script>

    import $ from 'jquery';
    import _ from 'underscore'; // findWhere
    import ScNotification from '../../shared/common/ScNotification.vue';
    import ScCommonUtil from '../../shared/common/ScCommonUtil';
    import ScIcon from '../../shared/common/ScIcon.vue';
    import DebugPresentationSearchIndex from './DebugPresentationSearchIndex.vue';
    import DebugUnusedPageLists from './DebugUnusedPageLists.vue';

    export default {
        name: 'DebugPresentation',
        components: {DebugPresentationSearchIndex, ScIcon, DebugUnusedPageLists},
        data: function() {
            return {
                draftPresentation: true,
                presLoading: false,
                presentationId: null,
                workspaceId: null,
                presData: {},
                pageThumbStatus: {},
                resourceThumbStatus: {},
                presProblems: [],
                presPublishable: null,
            };
        },

        mounted: function() {
            this.presentationId = Number(this.$route.params.showcaseId);
            this.workspaceId = Number(this.$route.params.workshopId);
            this.reloadPresentation();
        },

        methods: {
            presFromFile: function(evt) {
                let file = evt.target.files[0];
                //console.log('presFromFile', this, file);
                this.presLoading = true;
                //this.presData = {};
                let reader = new FileReader();
                reader.onload = (event) => {
                    let sc = JSON.parse(event.target.result);
                    let presData = sc.export ? sc.export : sc;
                    this.presData = presData;
                    this.presentationId = presData.presentation.id;
                    this.presLoading = false;
                };
                reader.readAsText(file);
            },
            regeneratePageRender: function(pageId) {
                this.pageThumbStatus[pageId] = {regenerating: true};
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_debug_render_page',
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId, page_id: pageId},
                    dataType: 'json'
                }).done(() => {
                    ScNotification.growlSuccessMsg('Generating.  Reload to view.');
                }).always(() => {
                    this.pageThumbStatus[pageId].regenerating = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'generating thumb');
                });
            },
            isPageThumbGenerating: function(pageId) {
                return this.pageThumbStatus[pageId] ? this.pageThumbStatus[pageId].regenerating : false;
            },
            isResourceThumbGenerating: function(resourceId) {
                return this.resourceThumbStatus[resourceId] ? this.resourceThumbStatus[resourceId].regenerating : false;
            },
            isResourcePageThumb: function(resourceId) {
                return !!_.findWhere(this.presData.page, {resource_pagethumb_id: resourceId});
            },
            checkPresentation: function() {
                this.presProblems.splice(0);
                this.presPublishable = null;
                $.ajax({
                    type: 'POST', url: "/main/presentations/ajax_check_resources_publishable_v2",
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId, attempt: 1}
                }).then((data) => {
                    this.presProblems.push(data.pres_problems);
                    this.presPublishable = data.resources_publishable;
                    ScNotification.growlSuccessMsg('Done.  Reload to view.');
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'checking files');
                });
            },
            removeRenderedPages: function() {
                this.presProblems.splice(0);
                this.presPublishable = null;
                $.ajax({
                    type: 'POST', url: "/main/presentations/ajax_debug_remove_rendered",
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
                }).then(() => {
                    ScNotification.growlSuccessMsg('Done.  Reload to view.');
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'removing rendered');
                });
            },
            checkAllUrls() {
                let checkUrl = (url, tag, debugObj) => {
                    fetch(url, {method: 'HEAD', mode: 'no-cors'}).then(() => {
                        // check dev console network tab for failures
                    }).catch(function() {
                        console.error(tag, 'error', debugObj);
                    });
                };

                Object.values(this.presData.resource).forEach((res) => {
                    if (res.content_type === 'weburl') return;
                    if (res.content_type === 'showcase_share') return;
                    if (res.content_type === 'image' && res.thumb_url && !res.url) return;
                    if (!res.url) {
                        console.log('res', res.id, 'has no url', res);
                        return;
                    }
                    if (res.content_type === 'font' || res.content_type === 'document' || res.content_type === 'movie'
                        || res.content_type === 'image') {
                        checkUrl(res.url, res.id, res);
                        return;
                    }
                    console.log('what to do with', res);
                });

                Object.values(this.presData.resourcemetadata).forEach((rmd) => {
                    if (rmd && rmd.name === 'video_2048_url') {
                        checkUrl(rmd.value, rmd.resource_id, rmd);
                    }
                    if (rmd && rmd.name === 'video_1024_url') {
                        checkUrl(rmd.value, rmd.resource_id, rmd);
                    }
                });
            },
            reloadPresentation: function() {
                this.presLoading = true;
                this.presData = {};
                let url = '/api/v1/showcases/'+this.presentationId;
                let urlArgs = {workspace_id: this.workspaceId};
                if (this.draftPresentation) {
                    url = '/main/editor/ajax_draft_showcase'
                    urlArgs = {workspace_id: this.workspaceId, presentation_id: this.presentationId}
                }
                $.ajax({
                    method: 'GET', url: url, data: urlArgs
                }).done((data) => {
                    //this.presData = data;
                    this.presData = data.export;
                    this.presLoading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'loading draft presentation');
                    this.presLoading = false;
                });
            },
            compileDraftPres: function() {
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_debug_compile_draft_pres',
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId},
                    dataType: 'json'
                }).done(() => {
                    ScNotification.growlSuccessMsg('Generating.  Reload to view.');
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'compiling pres');
                });
            },
            regenerateResourceThumb: function(resourceId) {
                this.resourceThumbStatus[resourceId] = {regenerating: true};
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_debug_regenerate_resource_thumb',
                    data: {
                        workspace_id: this.workspaceId,
                        resource_id: resourceId
                    }, dataType: 'json'
                }).done(() => {
                    ScNotification.growlSuccessMsg('Generating thumb, reload to view');
                }).always(() => {
                    this.resourceThumbStatus[resourceId].regenerating = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'regenerating thumb');
                });
            },
            fmtByteSize(size) {
                return ScCommonUtil.filesizeWithBytes(size);
            },
            allHostpostToPaddingSm() {
                if (!confirm('This is mostly UNTESTED, are you sure?')) return;
                $.ajax({
                    method: 'POST', url: '/main/presentations/ajax_debug_update_hotspot_padding_sm',
                    data: {workspace_id: this.workspaceId, presentation_id: this.presentationId}
                }).done(() => {
                    ScNotification.growlSuccessMsg('Updated.  Wait 2 mins then reload to view.');
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'resizing');
                });
            },
        },

        computed: {
            resourcesExcludingThumbs() {
                if (!this.presData || !this.presData.resource) return [];
                return Object.values(this.presData.resource).filter((res) => {
                    return !this.isResourcePageThumb(res.id);
                });
            },
            targetResourceIds: function() {
                let targetResourceIds = [];
                if (this.presData.hotspot) {
                    Object.values(this.presData.hotspot).forEach((hs) => {
                        if (hs.target_resource_id) {
                            targetResourceIds.push(hs.target_resource_id);
                        }
                    });
                }
                // return only unique
                return targetResourceIds.filter((v, i, a) => {return a.indexOf(v) === i});
            },
            presStats() {
                return {
                  numPagelists: Object.values(this.presData.pagelist || {}).length,
                  numPages: Object.values(this.presData.page || {}).length,
                  numHotspots: Object.values(this.presData.hotspot || {}).length,
                  numResources: Object.values(this.presData.resource || {}).length,
                  numHotspotBranding: Object.values(this.presData.hotspot_branding || {}).length,
                  numResourceMetadata: Object.values(this.presData.resourcemetadata || {}).length,
                  numShareableResource: Object.values(this.presData.shareable_resource || {}).length,
                  numShareablePageResource: Object.values(this.presData.shareable_page_resource || {}).length,
                }
            },
            resourceStatsByContentType() {
                let statsByContentType = {};
                if (this.presData && this.presData.resource) {
                    Object.values(this.presData.resource).forEach((r) => {
                        if (r.content_type) {
                            let statsObject = statsByContentType[r.content_type];
                            if (!statsObject) statsObject = {totalBytes: 0,
                                numResources: 0, totalBytes1024Video: 0, totalBytes2048Video: 0 };
                            statsObject.contentType = r.content_type;
                            statsObject.numResources += 1;
                            statsObject.totalBytes += r.bytesize;
                            statsByContentType[r.content_type] = statsObject;
                            let rmd1024Size = _.findWhere(this.presData.resourcemetadata, {resource_id: r.id,
                                name: 'video_1024_size'});
                            let rmd2048Size = _.findWhere(this.presData.resourcemetadata, {resource_id: r.id,
                                name: 'video_2048_size'});
                            if (rmd1024Size) statsObject.totalBytes1024Video += Number(rmd1024Size.value);
                            if (rmd2048Size) statsObject.totalBytes2048Video += Number(rmd2048Size.value);
                        }
                    });
                }
                return statsByContentType;
            }
        }
    };




</script>