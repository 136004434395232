<template>
    <div>
        <button type="button" :disabled="presIndexLoading" @click="fetchSearchIndexContents">List search index</button>
        <div v-if="presIndexResults.length > 0">
            <div v-for="(indexItem, idx) in presIndexResults" :key="'pir' + idx">
                {{indexItem}}
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import ScNotification from "../../shared/common/ScNotification.vue";

    export default {
        name: "DebugPresentationSearchIndex",
        props: {
            workspaceId: {type: Number, default: null},
            presentationId: {type: Number, default: null}
        },
        data() {
            return {
                presIndexLoading: false,
                presIndexResults: []
            }
        },
        methods: {
            fetchSearchIndexContents() {
                this.presIndexLoading = true;
                this.presIndexResults.splice(0);
                $.ajax({
                    method: 'GET', url: '/main/presentations/ajax_debug_pres_search_index',
                    data: {
                        workspace_id: this.workspaceId,
                        presentation_id: this.presentationId
                    }
                }).done((data) => {
                    this.presIndexResults.push(...data.pres_index_results);


                }).always(() => {
                    this.presIndexLoading = false;
                }).fail((jqXhr) => {
                    ScNotification.growlXhrError(jqXhr, 'loading search index');
                });
            }
        }
    }
</script>
